
button {
    padding: 4px 16px;
    border-radius: 4px;
}

.Home, .NoWallet, .SwitchChain {
    width: 800px;
    margin: 0 auto;
}

.Home .editor-link {
    text-align: center;
    padding: 16px;
    font-size: 2.0em;
}

.Editor {
    display: flex;
    flex-direction: row;
    margin: 0 auto;
}

.editor-controls {
    width: 600px;
    margin: 0 auto;
}

.MintBar {
    text-align: center;
    margin-top: 12px;
}

.ColorPicker {
    margin: 12px auto;
    padding: 3px 3px 0px 3px;
    border: 4px solid var(--black);
    border-radius: 4px;
}

.ColorPicker .react-colorful {
    width: 100%;
    height: 400px;
    margin-bottom: 30px;
}

.pfp {
    margin: 30px auto;
}

.pfp object, .pfp svg {
    border: 1px solid var(--black);
}

.Token {
    margin: 0 auto;
    width: 800px;
}

.Token table {
    padding: 16px;
}

.Token table th {
    text-align: left;
    padding-right: 8px;
}

.Address {
    margin: 0 auto;
    width: 800px;
}

.Address .pfpers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.Address .pfpers .pfp {
    width: 176px;
    height: 176px;
    margin: 12px;
    margin-bottom: 42px;
}
