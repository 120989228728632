:root {
    --black: #000019;
    --blue: #525DDD;
    --yellow: #F2CB04;
}

@import url('https://fonts.googleapis.com/css2?family=Trispace&display=swap');

body {
    margin: 0;
    font-family: 'Trispace', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--yellow);
    color: var(--black);
}

a {
    color: var(--blue);
}
